import { UPDATE_CART, RESET_TOTAL_CART } from "./actionTypes";

const initialState = {
  data: {
    productQuantity: 0,
    totalPrice: 0,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        data: action.payload,
      };
    case RESET_TOTAL_CART:
      return {
        ...state,
        data: initialState.data,
      };
    default:
      return state;
  }
}
