import { PLACE_ORDER, PAYMENT_FAILED } from "./actionTypes";

const initialState = {
    checkout: [],
    errorDetails: {},
    errors: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, errors: false, checkout: action.payload };
        case PAYMENT_FAILED:
            return { ...state, errors: true, errorDetails: action.payload };
        default:
            return state;
    }
}
