export const formatPrice = price => {
    if (price) {
        let formatedPrice = parseFloat(price);
        formatedPrice = formatedPrice.toFixed(2);
        return formatedPrice;
    }
    return 0;
};

export const formatPriceWithRestaurantDetails = (priceString, {decimal_moneda, simbolo_moneda, separador_mil_moneda}) => 
    localStorage.getItem("currencySymbolAlign") === "left" ? (simbolo_moneda || localStorage.getItem("currencyFormat")) + ' ' + parseFloat(priceString).toFixed(decimal_moneda === 0 ? 0 : 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : parseFloat(formatPrice(priceString)).toFixed(decimal_moneda === 0 ? 0 : 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + (simbolo_moneda || localStorage.getItem("currencyFormat"))
