export const WEBSITE_URL = "https://loenviamos.restometrics.pe/public"; //https://panel.budegom.com
export const IMAGE_URL = "https://loenviamos.restometrics.pe/public";
/* export const IMAGE_URL = "https://file-loenviamostest.s3.amazonaws.com"; */

export const URL_GET_TOKEN_VISA_TEST =
  "https://apisandbox.vnforappstest.com/api.security/v1/security";
export const URL_GET_SESSION_VISA_TEST =
  "https://apisandbox.vnforappstest.com/api.ecommerce/v2/ecommerce/token/session/";
export const URL_TRANSACTION_VISA_TEST =
  "https://apisandbox.vnforappstest.com/api.authorization/v3/authorization/ecommerce/";
export const URL_GET_TOKEN_VISA_PROD =
  "https://apiprod.vnforapps.com/api.security/v1/security";
export const URL_GET_SESSION_VISA_PROD =
  "https://apiprod.vnforapps.com/api.ecommerce/v2/ecommerce/token/session/";
export const URL_TRANSACTION_VISA_PROD =
  "https://apiprod.vnforapps.com/api.authorization/v3/authorization/ecommerce/";
export const URL_IP_CLIENTE = "https://api.ipify.org";
